import { useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import Head from 'next/head'
import cx from 'classnames'
import { useRouter } from 'next/router'

import blocks from './blocks'
import { useTranslations, interpolate } from 'common/language'
import classNames from './styles.module.scss'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import structuredDataSchema from 'common/utils/structuredDataSchema'


GenericPageView.propTypes = {
  data: PropTypes.shape({
    meta: PropTypes.shape({
      canonical_url: PropTypes.string,
      seo_title: PropTypes.string,
      type: PropTypes.string,
      search_description: PropTypes.string,
      hreflang_data: PropTypes.object,
      noindex: PropTypes.bool,
      nofollow: PropTypes.bool,
    }),
    title: PropTypes.string,
    seo_keywords: PropTypes.string,
    slug: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.object,
    })),
    map_locations: PropTypes.array,
    reviews_parameters: PropTypes.object,
  }).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  seoContext: PropTypes.object,
  languageCode: PropTypes.string,
}

GenericPageView.defaultProps = {
  title: '',
  description: '',
  seoContext: {},
  languageCode: undefined,
}

const pageNamePath = {
  'home.CountryPage': 'meta.seo_context.country_name',
  'home.LanguagePage': 'meta.seo_context.language_name',
  'home.DestinationPage': 'meta.seo_context.destination_name',
}

export default function GenericPageView({ data, title, description, seoContext, languageCode }) {
  const { gettext } = useTranslations()
  const { asPath } = useRouter()
  const paddingNeeded = useMemo(() => {
    return ![
      'three_line_hero_image',
      'filter_hero_image',
      'hero_image_advertising_animated',
      'hero_image_advertising_simple',
      'three_line_hero_image_with_seo_template',
      'home_page_hero_image_with_quick_filter',
    ].includes(get(data, 'content[0].type'))
  }, [get(data, 'content[0].type', '')])
  const components = useMemo(() => data.content.map(({ type, value, id }, index, arr) => {
    const Component = blocks[type]
    const valueData = type === 'map'
      ? get(data, 'map_locations', [])
      : type === 'product_reviews_list'
        ? data.reviews_parameters
        : value
    return Component ? <div key={id} data-qa={type}><Component data={valueData} type={type} pageType={get(data, 'meta.type')} seoContext={seoContext} languageCode={languageCode} priorityLoad={index < 2} lastBlock={index === arr.length - 1} /></div> : null
  }), [data.content])
  const firstImageData = data.content.find(({ type }) => type.search('hero_') > -1)
  const ogImage = useMemo(() => getImgFromBynder(get(firstImageData, 'value.image.bynder', {}), ['webImage']), [firstImageData])
  const origin = useMemo(() => typeof window === 'undefined' ? process.env.ORIGIN_URL : window.location.origin, [])
  const robots = useMemo(() => Object.entries(pick(data.meta, ['nofollow', 'noindex'])).reduce((arr, [key, value]) => value ? [...arr, key] : arr, []), [data.meta])
  const seoTitleDescription = useMemo(() => {
    if(['home.CountryPage', 'home.DestinationPage', 'home.LanguagePage'].includes(get(data, 'meta.type'))) {
      return {
        title,
        description,
      }
    }
    return {
      title: get(data, 'meta.seo_title', ''),
      description: get(data, 'meta.search_description', ''),
    }
  }, [data.meta])
  const canonicalDe = useMemo(() => {
    return origin + (get(data, 'meta.hreflang_data.de') || `/de${asPath}`)
  }, [data.meta, origin])
  const canonicalFr = useMemo(() => {
    return origin + (get(data, 'meta.hreflang_data.fr') || `/fr${asPath}`)
  }, [data.meta, origin])
  const isSchemaEnabled = useMemo(() => get(data, 'reviews_parameters.reviews_count', 0) > 0 && ['home.CountryPage', 'home.DestinationPage', 'home.LanguagePage'].includes(get(data, 'meta.type')) && !['sprachaufenthalt-schweiz/zuerich', 'sejour-linguistique-suisse/zurich'].includes(get(data, 'slug')), [data.meta, data?.reviews_parameters?.reviews_count, data?.slug])
  const schema = useMemo(() => isSchemaEnabled && structuredDataSchema({
    url: process.env.ORIGIN_URL + get(data, 'meta.canonical_url'),
    name: interpolate(gettext('Language Stay %s'), [get(data, pageNamePath[data.meta.type])]),
    score: get(data, 'reviews_parameters.rating'),
    reviewCount: get(data, 'reviews_parameters.reviews_count'),
  }), [isSchemaEnabled, data.meta, data.reviews_parameters, isSchemaEnabled])
  return (
    <div className={cx(paddingNeeded && classNames.padding)}>
      <Head>
        <title>{seoTitleDescription.title}</title>
        <meta name="description" content={seoTitleDescription.description} key="description" />
        <meta name="keywords" content={get(data, 'seo_keywords', '')} />
        <meta property="og:title" content={seoTitleDescription.title} key="og-title" />
        <meta property="og:description" content={seoTitleDescription.description} key="og-description" />
        <meta property="og:image" content={ogImage} key="og-image" />
        <meta property="og:url" content={origin + get(data, 'meta.canonical_url', '')} key="og-url" />
        <meta property="og:type" content="website" key="og-type" />
        <link rel="canonical" href={origin + get(data, 'meta.canonical_url', '')} />
        <link rel="alternate" hrefLang="de" href={canonicalDe} />
        <link rel="alternate" hrefLang="fr" href={canonicalFr} />
        <link rel="alternate" hrefLang="x-default" href={origin + get(data, 'meta.hreflang_data.default', '')} />
        {!isEmpty(robots) && (
          <meta name="robots" content={robots.join(',')} />
        )}
        {isSchemaEnabled && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: schema }}
          />
        )}
      </Head>
      {components}
    </div>
  )
}
